const IconArrowPrevious = () => (
    <svg fill="none" viewBox="0 0 16 12" xmlns="http://www.w3.org/2000/svg">
        <g
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2">
            <path d="m15 6h-13.99892" />
            <path d="m6.72656 11-5.726828-5 5.726828-5" />
        </g>
    </svg>
);

export default IconArrowPrevious;
